import axios from 'axios';

import { setAlert } from './alert';

import {
  REGISTER_SUCCESS,
  REGISTER_FAIL,
  LOGIN_SUCCESS,
  LOGIN_FAIL,
  USER_LOADED,
  AUTH_ERROR,
  LOGOUT,
} from './types';

import setAuthToken from '../utils/setAuthToken';

// Load User
export const loadUser = () => async dispatch => {
  if (localStorage.token) {
    setAuthToken(localStorage.token);
  }

  try {
    const res = await axios.get(`${process.env.REACT_APP_API_SERVER}/api/auth`);
    dispatch({
      type: USER_LOADED,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: AUTH_ERROR,
    });
  }
};

// Register a User
export const register =
  ({ name, uid, password, role, simnumber }, load = true) =>
  async dispatch => {
    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
    };
    let bodyObj = {
      name,
      uid,
      password,
      role,
    };
    if (simnumber) {
      bodyObj.simnumber = simnumber;
    }

    const body = JSON.stringify(bodyObj);
    try {
      const res = await axios.post(
        `${process.env.REACT_APP_API_SERVER}/api/users`,
        body,
        config,
      );
      if (load) {
        dispatch({
          type: REGISTER_SUCCESS,
          payload: res.data,
        });

        dispatch(loadUser());
      } else {
        dispatch(setAlert('User Created Success!', 'success'));
      }
    } catch (err) {
      const errors = err.response.data.errors;
      if (errors) {
        errors.forEach(error => dispatch(setAlert(error.msg, 'danger')));
      }
      if (load)
        dispatch({
          type: REGISTER_FAIL,
        });
      throw err;
    }
  };

// Login User
export const login = (uid, password) => async dispatch => {
  const config = {
    headers: {
      'Content-Type': 'application/json',
    },
  };

  const body = JSON.stringify({ uid, password });
  try {
    const res = await axios.post(
      `${process.env.REACT_APP_API_SERVER}/api/auth`,
      body,
      config,
    );
    dispatch({
      type: LOGIN_SUCCESS,
      payload: res.data,
    });
    dispatch(loadUser());
  } catch (err) {
    const errors = err.response.data.errors;
    if (errors) {
      errors.forEach(error => dispatch(setAlert(error.msg, 'danger')));
    }
    dispatch({
      type: LOGIN_FAIL,
    });
  }
};

// Logout / Clear Profile

export const logout = () => dispatch => {
  dispatch({ type: LOGOUT });
};
