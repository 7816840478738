import logo200Image from '../assets/img/logo/logo_200.png';
import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';
import { Button, Form, FormGroup, Input, Label } from 'reactstrap';
import { connect } from 'react-redux';
import { register, login } from './../actions/auth';
import { setAlert } from './../actions/alert';

import { Redirect } from 'react-router-dom';

const AuthForm = props => {
  const [formData, setFormData] = useState({
    name: '',
    uid: '',
    password: '',
    password2: '',
    role: 'Client',
  });

  useEffect(() => {
    setFormData(formData);
  }, [formData]);

  const { name, uid, password, password2, role } = formData;

  const isLogin = () => {
    return props.authState === STATE_LOGIN;
  };

  const isSignup = () => {
    return props.authState === STATE_SIGNUP;
  };

  const changeAuthState = authState => event => {
    event.preventDefault();

    props.onChangeAuthState(authState);
  };

  const onChange = e => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = event => {
    event.preventDefault();
    if (props.authState === STATE_LOGIN) {
      props.login(uid, password);
    } else {
      if (password !== password2) {
        props.setAlert('Passwords does not match', 'danger');
      } else {
        props.register({ name, uid, password, role });
      }
    }
  };

  const generateID = e => {
    e.preventDefault();
    let id = Math.random().toString(36).slice(-10);

    setFormData({ ...formData, uid: id });
  };

  const renderButtonText = () => {
    const { buttonText } = props;

    if (!buttonText && isLogin()) {
      return 'Login';
    }

    if (!buttonText && isSignup()) {
      return 'Signup';
    }

    return buttonText;
  };

  const {
    showLogo,
    usernameLabel,
    usernameInputProps,
    uidLabel,
    uidInputProps,
    roleInputProps,
    roleLabel,
    passwordLabel,
    passwordInputProps,
    confirmPasswordLabel,
    confirmPasswordInputProps,
    children,
    onLogoClick,
  } = props;

  // Redirect if logged in

  if (props.isAuthenticated) {
    return <Redirect to="/" />;
  }

  return (
    <Form onSubmit={handleSubmit}>
      {showLogo && (
        <div className="text-center pb-4">
          <img
            src={logo200Image}
            className="rounded"
            style={{ width: 60, height: 60, cursor: 'pointer' }}
            alt="logo"
            onClick={onLogoClick}
          />
        </div>
      )}
      {isSignup() && (
        <FormGroup>
          <Label for={usernameLabel}>{usernameLabel}</Label>
          <Input
            {...usernameInputProps}
            name="name"
            value={name}
            onChange={e => onChange(e)}
          />
        </FormGroup>
      )}
      <FormGroup>
        <Label for={uidLabel}>{uidLabel}</Label>
        {isSignup() && (
          <>
            <Input
              {...uidInputProps}
              name="uid"
              value={uid}
              placeholder="click below to auto generate 10 - digit Id."
              onChange={e => onChange(e)}
            />

            <Button
              className="bg-gradient-theme-left border-0"
              onClick={generateID}
            >
              Generate ID
            </Button>
          </>
        )}

        {!isSignup() && (
          <Input
            {...uidInputProps}
            name="uid"
            value={uid}
            placeholder="your ID"
            onChange={e => onChange(e)}
          />
        )}
      </FormGroup>

      <FormGroup>
        <Label for={passwordLabel}>{passwordLabel}</Label>

        <Input
          {...passwordInputProps}
          value={password}
          name="password"
          onChange={e => onChange(e)}
        />
      </FormGroup>
      {isSignup() && (
        <FormGroup>
          <Label for={confirmPasswordLabel}>{confirmPasswordLabel}</Label>
          <Input
            {...confirmPasswordInputProps}
            name="password2"
            value={password2}
            onChange={e => onChange(e)}
          />
        </FormGroup>
      )}
      {isSignup() && (
        <FormGroup>
          <Label for={roleLabel}>{roleLabel}</Label>
          <Input
            {...roleInputProps}
            name="role"
            value={role}
            onChange={e => onChange(e)}
          >
            {' '}
            <option>Client</option>
            <option>User</option>
          </Input>
        </FormGroup>
      )}
      {/* <FormGroup check>
        <Label check>
          <Input type="checkbox" />{' '}
          {isSignup() ? 'Agree the terms and policy' : 'Remember me'}
        </Label>
      </FormGroup> */}
      <hr />
      <Button
        size="lg"
        className="bg-gradient-theme-left border-0"
        block
        onClick={handleSubmit}
      >
        {renderButtonText()}
      </Button>

      <div className="text-center pt-1">
        <h6>or</h6>
        <h6>
          {isSignup() ? (
            <a href="#login" onClick={changeAuthState(STATE_LOGIN)}>
              Login
            </a>
          ) : (
            <a href="#signup" onClick={changeAuthState(STATE_SIGNUP)}>
              Signup
            </a>
          )}
        </h6>
      </div>

      {children}
    </Form>
  );
};

export const STATE_LOGIN = 'LOGIN';
export const STATE_SIGNUP = 'SIGNUP';

AuthForm.propTypes = {
  authState: PropTypes.oneOf([STATE_LOGIN, STATE_SIGNUP]).isRequired,
  showLogo: PropTypes.bool,
  usernameLabel: PropTypes.string,
  usernameInputProps: PropTypes.object,
  uidLabel: PropTypes.string,
  uidInputProps: PropTypes.object,
  passwordLabel: PropTypes.string,
  passwordInputProps: PropTypes.object,
  confirmPasswordLabel: PropTypes.string,
  roleLabel: PropTypes.string,
  roleInputProps: PropTypes.object,
  confirmPasswordInputProps: PropTypes.object,
  onLogoClick: PropTypes.func,
  register: PropTypes.func,
  login: PropTypes.func,
  setAlert: PropTypes.func,
  isAuthenticated: PropTypes.bool,
};

AuthForm.defaultProps = {
  authState: 'LOGIN',
  showLogo: true,
  uidLabel: 'UserID',
  uidInputProps: {
    type: 'string',
    placeholder: '',
  },
  usernameLabel: 'Name',
  usernameInputProps: {
    type: 'string',
    placeholder: 'your name',
  },
  passwordLabel: 'Password',
  passwordInputProps: {
    type: 'password',
    placeholder: 'your password',
  },
  roleInputProps: {
    type: 'select',
    placeholder: 'select',
  },
  roleLabel: 'Role',
  confirmPasswordLabel: 'Confirm Password',
  confirmPasswordInputProps: {
    type: 'password',
    placeholder: 'confirm your password',
  },
  onLogoClick: () => {},
};

const mapStateToProps = state => ({
  isAuthenticated: state.auth.isAuthenticated,
});
export default connect(mapStateToProps, { login, setAlert, register })(
  AuthForm,
);
