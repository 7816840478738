import Avatar from '../../components/Avatar';
import { UserCard } from '../../components/Card';
import SearchInput from '../../components/SearchInput';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { logout } from '../../actions/auth';
import { useHistory, withRouter } from 'react-router-dom';

import { MdClearAll, MdExitToApp, MdPersonPin } from 'react-icons/md';
import {
  Button,
  ListGroup,
  ListGroupItem,
  Nav,
  Navbar,
  NavItem,
  NavLink,
  Popover,
  PopoverBody,
} from 'reactstrap';
import bn from '../../utils/bemnames';

const bem = bn.create('header');

const Header = props => {
  const { user, loading } = props.auth;

  const history = useHistory();
  const [state, setState] = useState({
    isOpenNotificationPopover: false,
    isOpenUserCardPopover: false,
  });

  const toggleUserCardPopover = () => {
    setState({ ...state, isOpenUserCardPopover: !state.isOpenUserCardPopover });
  };

  const handleSidebarControlButton = event => {
    event.preventDefault();
    event.stopPropagation();

    document.querySelector('.cr-sidebar').classList.toggle('cr-sidebar--open');
  };

  const handleLogout = () => {
    props.logout();
    history.push('/login');
  };

  const handleProfileRoute = () => {
    history.push('/profile');
  };

  return (
    <Navbar light expand className={bem.b('bg-white')}>
      <Nav navbar className="mr-2">
        <Button outline onClick={handleSidebarControlButton}>
          <MdClearAll size={25} />
        </Button>
      </Nav>
      {/* <Nav navbar>
        <SearchInput />
      </Nav> */}
      {!loading && (
        <Nav navbar className={bem.e('nav-right')}>
          <NavItem>
            <NavLink id="Popover2">
              <Avatar
                src={user === null ? null : user.avatar}
                onClick={toggleUserCardPopover}
                className="can-click"
              />
            </NavLink>
            <Popover
              placement="bottom-end"
              isOpen={state.isOpenUserCardPopover}
              toggle={toggleUserCardPopover}
              target="Popover2"
              className="p-0 border-0"
              style={{ minWidth: 250 }}
            >
              <PopoverBody className="p-0 border-light">
                <UserCard
                  avatar={user === null ? null : user.avatar}
                  title={user === null ? 'NA' : user.name}
                  subtitle={user === null ? 'Please Login' : user.email}
                  className="border-light"
                >
                  <ListGroup flush>
                    <ListGroupItem
                      tag="button"
                      action
                      onClick={handleProfileRoute}
                      className="border-light"
                    >
                      <MdPersonPin /> Profile
                    </ListGroupItem>

                    <ListGroupItem
                      tag="button"
                      action
                      onClick={handleLogout}
                      className="border-light"
                    >
                      <MdExitToApp /> Logout
                    </ListGroupItem>
                  </ListGroup>
                </UserCard>
              </PopoverBody>
            </Popover>
          </NavItem>
        </Nav>
      )}
    </Navbar>
  );
};

Header.propTypes = {
  logout: PropTypes.func,
};

const mapStateToProps = state => ({
  auth: state.auth,
});

export default compose(
  withRouter,
  connect(mapStateToProps, { logout }),
)(Header);
