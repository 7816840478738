import logo200Image from '../../assets/img/logo/logo_200.png';
import sidebarBgImage from '../../assets/img/sidebar/sidebar-4.jpg';
import SourceLink from '../../components/SourceLink';
import React, { useState } from 'react';
import { connect } from 'react-redux';
// import { FaGithub } from 'react-icons/fa';
import {
  MdAccountCircle,
  MdArrowDropDownCircle,
  MdBorderAll,
  MdBrush,
  MdChromeReaderMode,
  MdDashboard,
  MdExtension,
  MdGroupWork,
  MdInsertChart,
  MdKeyboardArrowDown,
  MdNotificationsActive,
  MdPages,
  MdRadioButtonChecked,
  MdSend,
  MdStar,
  MdTextFields,
  MdViewCarousel,
  MdViewDay,
  MdViewList,
  MdPlace,
  MdWeb,
  MdWidgets,
  MdSettings
} from 'react-icons/md';
import { NavLink } from 'react-router-dom';
import {
  // UncontrolledTooltip,
  Collapse,
  Nav,
  Navbar,
  NavItem,
  NavLink as BSNavLink,
} from 'reactstrap';
import bn from '../../utils/bemnames';

const sidebarBackground = {
  backgroundImage: `url("${sidebarBgImage}")`,
  backgroundSize: 'cover',
  backgroundRepeat: 'no-repeat',
};

const navUserItems = [
  { to: '/charts', name: 'charts', exact: false, Icon: MdInsertChart },
  { to: '/tables', name: 'tables', exact: false, Icon: MdBorderAll },
  {
    to: '/profile',
    name: 'user profile',
    exact: false,
    Icon: MdChromeReaderMode,
  },
  { to: '/settings', name: 'settings', exact: false, Icon: MdSettings },

  {
    to: '/logout-modal',
    name: 'Logout',
    exact: false,
    Icon: MdViewCarousel,
  },
];
const navAdminItems = [
  { to: '/', name: 'dashboard', exact: true, Icon: MdDashboard },
  {
    to: '/profile',
    name: 'admin profile',
    exact: false,
    Icon: MdChromeReaderMode,
  },
  { to: '/tables', name: 'tables', exact: false, Icon: MdBorderAll },
  {
    to: '/manage-user',
    name: 'Manage User',
    exact: false,
    Icon: MdAccountCircle,
  },
  {
    to: '/charts',
    name: 'Charts',
    exact: false,
    Icon: MdChromeReaderMode,
  },
  {
    to: '/manage-client',
    name: 'Manage Clients',
    exact: false,
    Icon: MdAccountCircle,
  },
  // {
  //   to: '/maps',
  //   name: 'Maps',
  //   exact: true,
  //   Icon: MdPlace,
  // },
  { to: '/settings', name: 'settings', exact: false, Icon: MdSettings },
  {
    to: '/logout-modal',
    name: 'Logout',
    exact: false,
    Icon: MdViewCarousel,
  },
];

const navClientItems = [
  { to: '/', name: 'dashboard', exact: true, Icon: MdDashboard },
  {
    to: '/profile',
    name: 'client profile',
    exact: false,
    Icon: MdChromeReaderMode,
  },
  { to: '/tables', name: 'tables', exact: false, Icon: MdBorderAll },
  { to: '/charts', name: 'charts', exact: false, Icon: MdInsertChart },
  {
    to: '/manage-profile',
    name: 'Manage Users',
    exact: false,
    Icon: MdAccountCircle,
  },
  // {
  //   to: '/maps',
  //   name: 'Maps',
  //   exact: true,
  //   Icon: MdPlace,
  // },
  { to: '/settings', name: 'settings', exact: false, Icon: MdSettings },
  {
    to: '/logout-modal',
    name: 'Logout',
    exact: false,
    Icon: MdViewCarousel,
  },
];

var navItems = navUserItems;

const bem = bn.create('sidebar');

const Sidebar = props => {
  if (
    props.auth === null ||
    props.auth.isAuthenticated === null ||
    props.auth.isAuthenticated === false ||
    props.auth.user === null
  ) {
    navItems = navUserItems;
  } else {
    const role = props.auth.user.role;

    if (role === 'Client') navItems = navClientItems;
    else if (role === 'Admin') navItems = navAdminItems;
    else navItems = navUserItems;
  }

  const [state, setState] = useState({
    isOpenComponents: true,
    isOpenContents: true,
    isOpenPages: true,
  });

  return (
    <aside className={bem.b()} data-image={sidebarBgImage}>
      <div className={bem.e('background')} style={sidebarBackground} />
      <div className={bem.e('content')}>
        <Navbar>
          <SourceLink className="navbar-brand d-flex">
            <img
              src={logo200Image}
              width="40"
              height="30"
              className="pr-2"
              alt=""
            />
            <span className="text-white">AproposDrive</span>
          </SourceLink>
        </Navbar>
        <Nav vertical>
          {navItems.map(({ to, name, exact, Icon }, index) => (
            <NavItem key={index} className={bem.e('nav-item')}>
              <BSNavLink
                id={`navItem-${name}-${index}`}
                className="text-uppercase"
                tag={NavLink}
                to={to}
                activeClassName="active"
                exact={exact}
              >
                <Icon className={bem.e('nav-item-icon')} />
                <span className="">{name}</span>
              </BSNavLink>
            </NavItem>
          ))}
        </Nav>
      </div>
    </aside>
  );
};

const mapStateToProps = state => ({
  auth: state.auth,
});
export default connect(mapStateToProps)(Sidebar);
