import { STATE_LOGIN, STATE_SIGNUP } from './components/AuthForm';
// import GAListener from 'components/GAListener';
import uPlot from 'uplot';
import { EmptyLayout, LayoutRoute, MainLayout } from './components/Layout';
import PageSpinner from './components/PageSpinner';
import AuthPage from './pages/AuthPage';
import React, { useEffect, Fragment } from 'react';
import componentQueries from 'react-component-queries';
import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom';
import './styles/reduction.scss';

import setAuthToken from './utils/setAuthToken';
import Alert from './components/Alert';
import PrivateRoute from './components/Routing/PrivateRoute';
import { loadUser } from './actions/auth';
import { DashBoardStateProvider } from './DashBoardContext';
import { QueryClient, QueryClientProvider } from 'react-query'

// Redux
import { Provider } from 'react-redux';
import store from './store';

if (localStorage.token) {
  setAuthToken(localStorage.token);
}

// const AlertPage = React.lazy(() => import('pages/AlertPage'));
const AuthModalPage = React.lazy(() => import('./pages/AuthModalPage'));
// const BadgePage = React.lazy(() => import('pages/BadgePage'));
// const ButtonGroupPage = React.lazy(() => import('pages/ButtonGroupPage'));
// const ButtonPage = React.lazy(() => import('./pages/ButtonPage'));
// const CardPage = React.lazy(() => import('pages/CardPage'));
const ChartPage = React.lazy(() => import('./pages/ChartPage'));
const DashboardPage = React.lazy(() => import('./pages/DashboardPage'));
// const DropdownPage = React.lazy(() => import('pages/DropdownPage'));
// const FormPage = React.lazy(() => import('pages/FormPage'));
// const InputGroupPage = React.lazy(() => import('pages/InputGroupPage'));
// const ModalPage = React.lazy(() => import('pages/ModalPage'));
// const ProgressPage = React.lazy(() => import('pages/ProgressPage'));
const TablePage = React.lazy(() => import('./pages/TablePage'));
// const TypographyPage = React.lazy(() => import('pages/TypographyPage'));
// const WidgetPage = React.lazy(() => import('pages/WidgetPage'));
const UserProfilePage = React.lazy(() => import('./pages/UserProfilePage'));
const ManageUserProfile = React.lazy(() => import('./pages/ManageUserProfile'));
const ManageClientProfile = React.lazy(() =>
  import('./pages/ManageClientProfile'),
);
const ManageUserProfileByAdmin = React.lazy(() =>
  import('./pages/ManageUserProfileByAdmin'),
);
const Settings = React.lazy(() => {
  return import('./pages/Settings')
})

// const BubbleMap = React.lazy(() => import('pages/MapPage'));
const getBasename = () => {
  return `/${process.env.PUBLIC_URL.split('/').pop()}`;
};

const queryClient = new QueryClient();

const App = props => {
  useEffect(() => {
    store.dispatch(loadUser());
  }, []);

  return (
    <QueryClientProvider client={queryClient}>
      <DashBoardStateProvider>
        <Provider store={store}>
          <BrowserRouter basename={getBasename()}>
            <Fragment>
              {/* <GAListener> */}
              <Alert />
              <Switch>
                <LayoutRoute
                  exact
                  path="/login"
                  layout={EmptyLayout}
                  component={props => (
                    <AuthPage {...props} authState={STATE_LOGIN} />
                  )}
                />
                <LayoutRoute
                  exact
                  path="/signup"
                  layout={EmptyLayout}
                  component={props => (
                    <AuthPage {...props} authState={STATE_SIGNUP} />
                  )}
                />

                <MainLayout breakpoint={props.breakpoint}>
                  <React.Suspense fallback={<PageSpinner />}>
                    <Switch>
                      <PrivateRoute exact path="/" component={DashboardPage} />
                      {/* <PrivateRoute exact path="/maps" component={BubbleMap} /> */}
                      <PrivateRoute
                        exact
                        path="/logout-modal"
                        component={AuthModalPage}
                      />
                      {/* <PrivateRoute
                        exact
                        path="/buttons"
                        component={ButtonPage}
                      /> */}
                      {/* <PrivateRoute exact path="/cards" component={CardPage} /> */}
                      {/* <PrivateRoute
                        exact
                        path="/widgets"
                        component={WidgetPage}
                      /> */}
                      <PrivateRoute
                        exact
                        path="/profile"
                        component={UserProfilePage}
                      />
                      <PrivateRoute
                        exact
                        path="/manage-profile"
                        component={ManageUserProfile}
                      />

                      <PrivateRoute
                        exact
                        path="/manage-user"
                        component={ManageUserProfileByAdmin}
                      />

                      <PrivateRoute
                        exact
                        path="/manage-client"
                        component={ManageClientProfile}
                      />
                      {/* <PrivateRoute
                        exact
                        path="/typography"
                        component={TypographyPage}
                      /> */}
                      {/* <PrivateRoute exact path="/alerts" component={AlertPage} /> */}
                      <PrivateRoute exact path="/tables" component={TablePage} />
                      {/* <PrivateRoute exact path="/badges" component={BadgePage} /> */}
                      {/* <PrivateRoute
                        exact
                        path="/button-groups"
                        component={ButtonGroupPage}
                      /> */}
                      {/* <PrivateRoute
                        exact
                        path="/dropdowns"
                        component={DropdownPage}
                      /> */}
                      {/* <PrivateRoute exact path="/progress" component={ProgressPage} /> */}
                      {/* <PrivateRoute exact path="/modals" component={ModalPage} /> */}
                      {/* <PrivateRoute exact path="/forms" component={FormPage} /> */}
                      {/* <PrivateRoute
                        exact
                        path="/input-groups"
                        component={InputGroupPage}
                      /> */}
                      <PrivateRoute exact path="/charts" component={ChartPage} />
                      <PrivateRoute exact path="/settings" component={Settings} />
                      <Redirect to="/login" />
                    </Switch>
                  </React.Suspense>
                </MainLayout>
              </Switch>
              {/* </GAListener> */}
            </Fragment>
          </BrowserRouter>
        </Provider>
      </DashBoardStateProvider>
    </QueryClientProvider>
  );
};

const query = ({ width }) => {
  if (width < 575) {
    return { breakpoint: 'xs' };
  }

  if (576 < width && width < 767) {
    return { breakpoint: 'sm' };
  }

  if (768 < width && width < 991) {
    return { breakpoint: 'md' };
  }

  if (992 < width && width < 1199) {
    return { breakpoint: 'lg' };
  }

  if (width > 1200) {
    return { breakpoint: 'xl' };
  }

  return { breakpoint: 'xs' };
};

export default componentQueries(query)(App);
