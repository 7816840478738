import React, { Children, createContext, useContext, useState } from 'react';

export const DashBoardContext = createContext();

export function DashBoardStateProvider({ children }) {
  const [allStatus, setAllStatus] = useState();
  const [weekChartData, setWeekChartData] = useState();
  const [simList, setSimList] = useState();
  const [mapData, setMapData] = useState();

  let value = {
    simList,
    allStatus,
    weekChartData,
    mapData,
    setAllStatus,
    setWeekChartData,
    setSimList,
    setMapData,
  };

  return (
    <DashBoardContext.Provider value={value}>
      {children}
    </DashBoardContext.Provider>
  );
}

export function useDashBoardState() {
  return useContext(DashBoardContext);
}
